<template>
  <div class="sidebar-menu">
    <ul>
      <li v-for="route in routeObj" :key="route.route">
        <router-link :to="`./${route.route}`">{{route.name}}<br>
          <em class="sidebar-menu__category">{{route.category}}</em>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenu',
  props: {
    routeObj: {
      type: Array
    },

    routeName: {
      type: String,
      default: 'Home'
    },

    routeID: {
      type: String,
      default: './01'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .sidebar-menu {
    // background-color: rgba($color: #000000, $alpha: 0.8);
    // background: linear-gradient(90deg,
    //   rgba($color: #000000, $alpha: 0.0) 0%,
    //   rgba($color: #000000, $alpha: 0.8) 30%,
    //   rgba($color: #000000, $alpha: 0.6) 75%,
    //   rgba($color: #000000, $alpha: 0.0) 100%);
    padding: 2rem 0;
    display: inline-block;
    // height: 100vh;
    position: sticky;
    top: 100px;
    // height: 100%;
    ul {
      list-style: none;
      padding-left: 0rem;
      color: #fff;
      li {
        margin-bottom: 1rem;
        a {
          color: #ccc;
          text-decoration: none;
          font-weight: 600;

          &.router-link-active {
            color: #D96704;
            text-decoration: underline;
          }
        }

      }
    }

    // Category
    &__category {
      margin-left: 10px;
    }
  }
</style>
